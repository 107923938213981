import React from 'react';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import _ from 'underscore';
import {Link} from 'react-router-dom';
import lodashGet from 'lodash/get';
import ONYXKEYS from '../../ONYXKEYS';
import ROUTES from '../../ROUTES';
import {
    getCompensationOverview,
    setDataToConfirmForUpload,
    downloadOverviewCSV,
    downloadTemplateCSV,
} from '../../libs/actions/Compensation';
import TableSortButtons from '../../components/TableSortButtons';
import CompensationNavBar from '../../components/CompensationNavBar';
import withCompensationAccessPermission from '../../components/withCompensationAccessPermission';
import compose from '../../libs/compose';
import round from '../../libs/round';
import {redirect} from '../../libs/actions/App';
import compensationPropTypes from './compensationPropTypes';

const propTypes = {
    /** From Onyx: All the current compensation data for employees */
    compensationOverview: PropTypes.arrayOf(compensationPropTypes),

    /** Comes from React Router */
    /* eslint-disable-next-line react/forbid-prop-types */
    match: PropTypes.any.isRequired,
};
const defaultProps = {
    compensationOverview: [],
};

class CompensationOverviewPage extends React.Component {
    constructor(props) {
        super(props);

        this.clearFilter = this.clearFilter.bind(this);
        this.updateTableSearch = this.updateTableSearch.bind(this);
        this.updateSort = this.updateSort.bind(this);
        this.openFileSelector = this.openFileSelector.bind(this);
        this.createCompensationRecords = this.createCompensationRecords.bind(this);

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const effectiveDate = `${yyyy}-${mm}-${dd}`;

        this.state = {
            sortBy: 'email',
            sortDirection: 'desc',
            tableSearchValue: '',
            effectiveDate,
        };
    }

    componentDidMount() {
        getCompensationOverview();
    }

    openFileSelector() {
        this.importCSVFileInput.click();
    }

    createCompensationRecords(e) {
        const reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function () {
            return function (readerOnloadEvent) {
                const fileContents = readerOnloadEvent.target.result;
                const fileLines = fileContents.split(/\r?\n/);
                const compensationRecords = _.reduce(fileLines, (finalResult, fileLine) => {
                    // Skip any header row
                    if (fileLine.substring(0, 14) === 'Effective Date') {
                        return finalResult;
                    }

                    const lineValues = fileLine.split(',');

                    if (!lineValues[0].trim()) {
                        return finalResult;
                    }

                    // Order of fields: Effective Date, Email, Local Currency, Currency Exchange, Reason, Cash Salary, Salary Exchanged for Equity, Grant Compensation Amount, Options
                    // Convert the dollars into cents again
                    return [
                        ...finalResult,
                        {
                            effectiveDate: lineValues[0],
                            email: lineValues[1],
                            localCurrency: lineValues[2],
                            localCurrencyExchangeRate: Number(lineValues[3]),
                            reason: lineValues[4],
                            salary: lineValues[5] ? round(Number(lineValues[5]) * 100) : 0,
                            salaryExchangedForEquity: lineValues[6] ? round(Number(lineValues[6]) * 100) : 0,
                            grantCompensationAmount: lineValues[7] ? round(Number(lineValues[7]) * 100) : 0,
                            numberOfOptions: lineValues[8] ? parseInt(lineValues[8], 10) : 0,
                        },
                    ];
                }, []);

                setDataToConfirmForUpload(compensationRecords);
                redirect(ROUTES.COMPENSATION_CONFIRM_UPLOAD);
            };
        })();

        reader.readAsText(e.target.files[0]);
    }

    /**
     * @param {String} value
     */
    updateTableSearch(value) {
        this.setState({
            tableSearchValue: value,
        });
    }

    /**
     * Update the way the table is sorted
     *
     * @param {String} field
     * @param {String} direction
     */
    updateSort(field, direction) {
        this.setState({
            sortBy: field,
            sortDirection: direction,
        });
    }

    /**
     * @param {String} value
     */
    updateEffectiveDate(value) {
        this.setState({
            effectiveDate: value,
        });
    }

    clearFilter() {
        this.setState({
            tableSearchValue: '',
        });
    }

    render() {
        const currencyUSDFormatter = new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'});
        const numberFormatter = new Intl.NumberFormat('en-EN', {});
        let sortedData = this.props.compensationOverview;

        // Apply an email filter if it's there
        if (this.state.tableSearchValue) {
            const searchValue = this.state.tableSearchValue.toLowerCase();
            sortedData = _.filter(sortedData, row => lodashGet(row, 'email', '').toLowerCase().indexOf(searchValue) > -1
                    || lodashGet(row, 'name', '').toLowerCase().indexOf(searchValue) > -1);
        }

        // Sort the data before rendering it
        sortedData = _.sortBy(sortedData, this.state.sortBy);
        if (this.state.sortDirection === 'asc') {
            sortedData.reverse();
        }
        return (
            <>
                <CompensationNavBar email={this.props.match.params.email} />

                <div className="card mt-4">
                    <div className="card-body">
                        <h5 className="card-title">Compensation Overview for All Employees</h5>

                        <form className="form-inline">
                            <label className="my-1 mr-2" htmlFor="tableSearchInput">Filter by name or email:</label>
                            <input
                                type="text"
                                className="form-control mr-2"
                                id="tableSearchInput"
                                value={this.state.tableSearchValue}
                                onChange={e => this.updateTableSearch(e.target.value)}
                            />
                            {this.state.tableSearchValue && (
                                <button
                                    type="button"
                                    className="btn btn-primary my-1 mx-2"
                                    onClick={this.clearFilter}
                                >
                                    Clear
                                </button>
                            )}
                            <label className="my-1 mr-2" htmlFor="effectiveDate">Download data effective:</label>
                            <input
                                type="date"
                                className="form-control m"
                                id="effectiveDate"
                                required
                                value={this.state.effectiveDate}
                                onChange={e => this.updateEffectiveDate(e.target.value)}
                            />
                            <button
                                type="button"
                                className="btn btn-primary my-1 ml-1"
                                onClick={() => downloadOverviewCSV(this.state.effectiveDate)}
                            >
                                Download Comp Overview
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary my-1 ml-1"
                                onClick={downloadTemplateCSV}
                            >
                                Download Bulk Change Template
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary my-1 ml-1 mr-2"
                                onClick={this.openFileSelector}
                            >
                                Import Bulk Compensation Changes
                            </button>
                            <input
                                ref={el => this.importCSVFileInput = el}
                                type="file"
                                accept=".csv"
                                onChange={this.createCompensationRecords}
                                className="hidden"
                            />
                        </form>
                    </div>

                    {this.props.compensationOverview.length > 0 && (
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        Email
                                        <TableSortButtons
                                            onChange={direction => this.updateSort('email', direction)}
                                            isCurrentlySorting={this.state.sortBy === 'email'}
                                            sortDirection={this.state.sortDirection}
                                        />
                                    </th>
                                    <th className="text-right">
                                        Cash Salary
                                        <TableSortButtons
                                            onChange={direction => this.updateSort('totalSalary', direction)}
                                            isCurrentlySorting={this.state.sortBy === 'totalSalary'}
                                            sortDirection={this.state.sortDirection}
                                        />
                                    </th>
                                    <th className="text-right">
                                        Salary Exchanged for Equity
                                        <TableSortButtons
                                            // eslint-disable-next-line max-len
                                            onChange={direction => this.updateSort('totalSalaryExchangedForEquity', direction)}
                                            isCurrentlySorting={this.state.sortBy === 'totalSalaryExchangedForEquity'}
                                            sortDirection={this.state.sortDirection}
                                        />
                                    </th>
                                    <th className="text-right">
                                        Grant Compensation Amount
                                        <TableSortButtons
                                            // eslint-disable-next-line max-len
                                            onChange={direction => this.updateSort('totalGrantCompensationAmount', direction)}
                                            isCurrentlySorting={this.state.sortBy === 'totalGrantCompensationAmount'}
                                            sortDirection={this.state.sortDirection}
                                        />
                                    </th>
                                    <th className="text-right">
                                        Options
                                        <TableSortButtons
                                            onChange={direction => this.updateSort('totalNumberOfOptions', direction)}
                                            isCurrentlySorting={this.state.sortBy === 'totalNumberOfOptions'}
                                            sortDirection={this.state.sortDirection}
                                        />
                                    </th>
                                    <th className="text-right">
                                        Total Comp
                                        <TableSortButtons
                                            onChange={direction => this.updateSort('totalCompensation', direction)}
                                            isCurrentlySorting={this.state.sortBy === 'totalCompensation'}
                                            sortDirection={this.state.sortDirection}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(sortedData, row => (
                                    <tr key={row.email}>
                                        <td>
                                            <Link to={ROUTES.getCompensationEditEmailRoute(row.email)}>
                                                {row.email}
                                            </Link>
                                        </td>
                                        <td className="text-right">
                                            {currencyUSDFormatter.format(row.totalSalary / 100)}
                                        </td>
                                        <td className="text-right">
                                            {currencyUSDFormatter.format(row.totalSalaryExchangedForEquity / 100)}
                                        </td>
                                        <td className="text-right">
                                            {currencyUSDFormatter.format(row.totalGrantCompensationAmount / 100)}
                                        </td>
                                        <td className="text-right">
                                            {numberFormatter.format(row.totalNumberOfOptions)}
                                        </td>
                                        <td className="text-right">
                                            {currencyUSDFormatter.format((row.totalCompensation) / 100)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </>
        );
    }
}

CompensationOverviewPage.propTypes = propTypes;
CompensationOverviewPage.defaultProps = defaultProps;

export default compose(
    withCompensationAccessPermission,
    withOnyx({
        compensationOverview: {
            key: ONYXKEYS.COMPENSATION_OVERVIEW,
        },
    }),
)(CompensationOverviewPage);
